@import '~bootstrap/scss/bootstrap';

$header-height: 100px;

// ------------------------------------------------------------------------------------------

html {
  font-size: 13px;
}

body {
  background-color: #f8f8f8;
}

.table {
  text-align: center;

  td,
  th {
    vertical-align: middle !important;
    white-space: nowrap;
    border-color: #f3f3f3;
  }
}

textarea {
  height: 8rem !important;
}

label {
  z-index: 10;
}

.table-striped > tbody > tr:nth-of-type(odd) > td,
.table-striped > tbody > tr:nth-of-type(odd) > th {
  --bs-table-accent-bg: #f3f3f3;
}

.d2h-tag {
  visibility: hidden;
}

// ------------------------------------------------------------------------------------------

.app-container {
  @extend .pt-5;
  @extend .px-5;
  margin-top: $header-height;
}

.app-main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: calc(80vh - #{$header-height});
}

.app-header {
  top: 0;
  position: fixed;
  z-index: 100;

  width: 100%;
  height: $header-height;

  display: flex;
  flex-direction: row;

  box-shadow: 0px -20px 29px 2px rgba(0, 0, 0, 0.78);
  background-color: white;

  padding-left: 20px;
  padding-right: 20px;

  .left {
    display: flex;
    align-items: center;
    justify-content: start;
    flex: 1;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .right {
    display: flex;
    align-items: center;
    justify-content: end;
    flex: 1;
  }

  .logo {
    width: 140px;
    cursor: pointer;
  }
}

.status-badge {
  width: 120px;
}

.pagination {
  &.loading {
    opacity: 0.6;
  }
}

.text-align-left {
  text-align: left !important;
}

.pass-rate {
  font-weight: bold;

  &.bad {
    color: #ca2929;
  }
  &.medium {
    color: #ddb533;
  }
  &.good {
    color: #058903;
  }
}

.icon-button {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  margin-left: 2px;
  margin-right: 2px;
  opacity: 0.7;

  &:not(.active) {
    visibility: hidden;
  }

  &:not(.disabled) {
    &:hover {
      cursor: pointer;
      opacity: 1;

      &:not(:active) {
        transform: scale(1.2);
      }
    }
  }

  .icon {
    height: 26px;
    width: 26px;
  }
}

.clickable {
  opacity: 0.85;

  &:disabled {
    opacity: 0.85 !important;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(:active):hover {
    opacity: 1;
    scale: 1.005;
  }
}

.editable {
  @extend .clickable;

  .preview {
    @extend h4;
    @extend b;
    padding: 0px;
    margin: 0px;
  }

  .label-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .label {
      font-weight: bold;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    .icon {
      width: 14px;
    }
  }
}

.bolt-details-table {
  table-layout: auto;
  text-align: start;

  td,
  th {
    white-space: nowrap;
    padding-top: 0;
    padding-bottom: 0;
  }

  td:last-child {
    width: 100%;
  }
}

.chart-container {
  height: 400px;
}

.key-value-add-btn {
  @extend .me-auto;
  min-width: 10rem;
}

.test-connection-modal {
  .modal-dialog {
    max-width: 1400px !important;
  }

  .modal-body {
    max-height: 85vh;
    overflow: scroll;
  }
}

.runtime-tokens-table {
  @extend .mx-2;
  text-align: left;
}

.help {
  width: 20px;
  height: 20px;
  cursor: pointer;

  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
}

.response-html-container {
  font-size: 0.8rem;
  background-color: #ddd;
  border-width: 1px;
  border-color: #111111;
}